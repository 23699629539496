@tailwind base;
@tailwind components;
@tailwind utilities;
.progress {
  position: sticky;
  top: 0;
  width: 80px;
  height: 80px;
  margin: 0;
  padding: 0;
}

.progress svg {
  transform: translateX(-100px) rotate(-90deg);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 5%;
  fill: none;
}

.bg {
  stroke: red;
  opacity: 0.2;
}

.progress .indicator {
  stroke: red;
}



.cust-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cust-section div {
  width: 200px;
  height: 300px;
  border: 2px dotted var(--accent);
  position: relative;
}

.cust-section-new {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  perspective: 500px;
}

.cust-section-new > div {
  width: 300px;
  height: 400px;
  position: relative;
  max-height: 90vh;
  margin: 20px;
  background: var(--white);
  overflow: hidden;
}

.cust-section-new img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.eden-card{
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.front{
  backface-visibility: hidden;
}
.back{
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.cardFlip{
  transform: rotateY(180deg);
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.player-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
}
.grid-cols-custom {
  grid-template-columns: repeat(3, 1fr);
}
.bg-darkLight {
  background: linear-gradient(to top,#F5F5F6 0%, #F5F5F6 50%, #0C1E20 50%, #0C1E20 100%);
}
.bg-lightDark {
  background: linear-gradient(to top, #0C1E20 0%, #0C1E20 50%, #F5F5F6 50%, #F5F5F6 100%);
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
    scroll-behavior: smooth;
  }
  body {
    @apply bg-background text-foreground;
  }

  .container {
    @apply px-4 sm:px-10 max-w-[1440px] mx-auto;
  }
  
}

.card-hover {
  background: linear-gradient(to right, #68d391, #4299e1); 
}
.card-hover:hover {
  background: linear-gradient(to right, #f687b3, #f6e05e); /* Replace with your actual hover color values */
}

.carousel_custom .thumbs {
  display: flex;
  gap: 0.5rem;
}
.carousel_header .carousel .control-dots  {
  bottom: 32px !important; 
}
.carousel_custom .thumb {
  border:none !important;
  width: auto !important;
  opacity: 50% !important;
  transition: all 0.2s ease-in-out !important;
}
.carousel_custom .thumb:hover {
 opacity: 100% !important;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .carousel_custom .thumbs {
    display: flex;
    margin-top: 2rem;
    gap: 0.5rem;
  }
  .scroller__inner {
    gap: 1rem;
  }
}
.carousel_custom li.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.carousel_custom li.thumb.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  opacity: 100% !important;
}
.carousel_custom li.thumb img {
  max-height: 128px;
}


.scroller {
  width: 100%;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  
}

.scroller[data-animated="true"] {
  overflow: hidden;
  /* -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent); */
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  /* animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite; */
}

/* .scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 200s;
} */

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */

:root {
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-primary-100: hsl(205, 15%, 58%);
  --clr-primary-400: hsl(215, 25%, 27%);
  --clr-primary-800: hsl(217, 33%, 17%);
  --clr-primary-900: hsl(218, 33%, 9%);
}
